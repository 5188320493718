import teamOne from "./team-1.png";
import teamTwo from "./team-2.png";
import teamThree from "./team-3.png";

import { ManagementBox } from "./ManagementBox";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Hero } from "./Hero";

function App() {
  return (
    <div className="flex flex-col justify-between">
      <Header />

      <main className="mb-auto">
        <Hero />
        <div className="container mx-auto px-10">
          <div className="w-100">
            <h3 className="text-orange mb-2 mt-4 text-2xl font-extrabold tracking-tight leading-none md:text-2xl xl:text-3xl ">
              Management Team
            </h3>
          </div>
          <ManagementBox
            imagePath={teamOne}
            name="Dr. Otto von Schlieffen"
            title="CEO and Co-Founder"
            email="votto@Glpsy.com"
            description="
            With over 30 years of academic research in the physiology of glucagon-like peptides, Dr. von Schlieffen realized an undeniable link between blood sugar control and wellness.  His conviction only galvanized once he recognized multiple disease states could also be addressed through sodium glucose cotransporter inhibitors. His best-selling book, “Der Einzige Weg zum Wohlbefinden,” earned Otto recognition for his unconventional approach to favoring pharmaceutical therapies over holistic approaches to wellness. Following his true calling, Otto focused on creating a solution that streamlined patient access to breakthrough medications, while providing rigorous adherence standards to ensure the best clinical and financial outcomes for all parties.  With the launch of Glpsy, his dream of delivering universal wellness can finally be realized.
           "
          />
          <ManagementBox
            imagePath={teamTwo}
            name="Dr. Lara Koperbollen"
            title="CMO and Co-Founder"
            email="klara@Glpsy.com"
            description="
            Lara's storied career spans two decades, starting with her groundbreaking study of the social structure of mountain gorillas in eastern and central Africa.  During her thirteen-year study, Dr. Koperbollen would synthesize her research on proto-agreeableness and postulate the “Oasis of Calm Principles.”  Her findings have been leveraged successfully by the largest marketing and social media organizations in the world, but Lara always believed pharmacology could benefit immensely from the psychology of undue influence.  After meeting Otto at a conference in Amsterdam, Lara realized Glpsy would be the ideal platform to help patients accept socially engineered goals for better health.
           "
          />
          <ManagementBox
            imagePath={teamThree}
            name="Wu Tan"
            title="CTO and Co-Founder"
            email="twu@Glpsy.com"
            description="
From an early age, Wu had a love for music and composed his first symphony at age 12. His fondness for composition and improvisational contexts led him to career in software development, focusing on preference-based optimization algorithms.  Choosing to leave university to pursue his passion, Wu developed a music recommendation algorithm, utilizing digital mapping to sequence similarities between songs. With over 47 patents, including the MPEG transport stream and the Matroska container format, Wu has quietly been a force in the evolution of music and technology. Often called “Wu-Tang” by his colleagues, because like the famous hip hop group, he is often described as Witty Unpredictable Talent and Natural Game. When approached by Dr. von Schlieffen he recognized the incredible potential of Glpsy and set to work on his most important composition.           "
          />
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default App;
