export const HeroContent = ({title, desc, color}) => {
  return (
    <>
      <h2 class={`max-w-2xl md:max-w-full mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-5xl lg:text-left text-center ${color}`}>{title}</h2>
      <p class="max-w-2xl md:max-w-full mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400 text-center lg:text-left">
        {desc}
      </p>
    </>
  );
};
