import { useEffect, useState } from "react";
import phone from "./phone.svg";
import TextTransition from "react-text-transition";

import { HeroContent } from "./HeroContent";

const WellnessHeader = (
  <HeroContent
    color="text-yellow"
    title="WELLNESS"
    desc="Glpsy is your personal AI Coach to wellness. Combining medication gamification with daily affirmations, Glpsy will make sure you hit
      your goal."
  />
);

const AdherenceHeader = (
  <HeroContent
    color="text-blue"
    title="ADHERENCE"
    desc="Glpsy achieves 120% Medication Possession Ratio through accelerated fulfillment. Using traditional PBM models we can guarantee this, at only 20% more cost."
  />
);

const CompensationHeader = (
  <HeroContent
    color="text-purple"
    title="COMPENSATION"
    desc="You want, we’ll pay it! Brokers and consultants just name your commission. We offer PMPM, PEPM, and shared-savings models!"
  />
);

const SavingsHeader = (
  <HeroContent
    color="text-pink"
    title="SAVINGS"
    desc="We do all the shared savings calculations ourselves, ensuring we always show success."
  />
);

const AccessHeader = (
  <HeroContent
    color="text-orange"
    title="ACCESS"
    desc="A team of telehealth experts will guarantee patient access to any medication. Just tell us the drug you need us to prescribe, and we
      will deliver it directly to your front door!"
  />
);

const heroContent = [WellnessHeader, AdherenceHeader, CompensationHeader, SavingsHeader, AccessHeader];

export const Hero = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      5000, // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <div className="text-center w-100">
        <h1 className="text-purple mb-2 mt-4 text-4xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl ">Wellness powered by Medication</h1>
      </div>
      <section class="bg-white border-b border-gray mb-10">
        <div class="grid max-w-screen-xl px-4 pt-4 mx-auto lg:gap-8 xl:gap-0 lg:pt-2 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <TextTransition>{heroContent[index % heroContent.length]}</TextTransition>
          </div>
          <div class="lg:mt-0 lg:col-span-5 lg:flex place-self-center">
            <img src={phone} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};
