export const Footer = ({ imagePath, name, title, description, email }) => {
  return (
    <footer className="p-3 bg-purple text-center text-white">
      <div className="container mx-auto px-10">
        <span className="md:inline block text-center">Glpsy</span>
        <span className="md:inline hidden mx-2">|</span>
        <a className="md:inline block text-center" href="mailto:aprilfools@glpysy.com">aprilfools@glpsy.com</a>
        <span className="md:inline mx-2 hidden">|</span>
        <a className="md:inline block text-center" href="http://www.glpys.com">www.glpsy.com</a>
      </div>
    </footer>
  );
};
