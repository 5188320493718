import logo from "./logo.svg";
import linkedin from "./linkedin.svg";
import podcast from "./podcast.svg";
import spotify from "./spotify.svg";

export const Header = () => {
  return (
    <header className="mb-2 shadow">
      <div className="container mx-auto p-4">
        <div className="flex items-center flex-row justify-end">
          <a href="http://www.linkedin.com" target="_blank" rel="noreferrer">
            <img src={linkedin} alt="" height={24} width={24} />
          </a>
          <a href="https://www.audible.com/ep/podcasts" target="_blank" rel="noreferrer">
            <img src={podcast} alt="" height={24} width={24} className="mx-2" />
          </a>
          <a href="https://open.spotify.com/" target="_blank" rel="noreferrer">
            <img src={spotify} alt="" height={24} width={24} />
          </a>
        </div>
        <div className="bg-white border-gray-200 py-2.5">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <img src={logo} className="mr-3 h-12" alt="logo" />
            <div className="items-center lg:order-2">
              <div className="flex items-center flex-row">
                <button className="bg-purple text-white p-2 rounded mx-4">Member Portal</button>
                <button className="text-purple border border-purple p-2 rounded">Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
