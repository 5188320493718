export const ManagementBox = ({imagePath, name, title, description, email}) => {
  return (
    <div className="xl:flex bg-white rounded-xl p-4 shadow-md mb-8">
      <img
        className="xl:rounded-tl-sm xl:rounded-bl-sm rounded-full mx-auto h-auto w-auto"
        src={imagePath}
        alt=""
      />
      <div className="pt-6 md:p-4 text-center xl:text-left space-y-4">
        <blockquote>
          <p className="text-sm">
           {description}
          </p>
        </blockquote>
        <div className="text-sm">
          <div className="dark:text-sky-400">{name}</div>
          <div className="font-bold">{title}</div>
          <div><a href={`mailto:${email}`} className="text-purple">{email}</a></div>
        </div>
      </div>
    </div>
  );
};
